import React, { useState, useEffect, useRef } from "react";
import { IconButton, Slider } from "@mui/material";
import {
  PlayCircleFilledWhite,
  Pause,
  SkipNext,
  SkipPrevious,
  VolumeUp,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import styles from "./styles.module.scss";
import { DirectusAudio, DirectusImage } from "app/core/common";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import CloseIcon from "@mui/icons-material/Close";
import { truncate } from "app/helpers/helper";
import { useHistory } from "react-router-dom";

// Custom styled slider
const GraySlider = styled(Slider)({
  color: "gray",
  "& .MuiSlider-thumb": {
    display: "none",
  },
  "& .MuiSlider-track": {
    backgroundColor: "gray",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#7a7a7a",
  },
});

const TimeSlider = styled(Slider)({
  color: "white",
  "& .MuiSlider-thumb": {
    display: "none",
  },
  "& .MuiSlider-track": {
    backgroundColor: "white",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#bfbfbf",
  },
});

export default function PlayerComponent() {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(100);
  const [totalTime, setTotalTime] = useState<number>(0); // Total time in seconds
  const audioRef = useRef(new Audio()); // Initialize without URL
  const history = useHistory();
  const {
    toggleMediaPlayerModal,
    actualTrack,
    play,
    pause,
    tooglePlayPause,
    handleNextTrack,
    handlePrevTrack,
  } = useMediaPlayer();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleNextTrack);

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("ended", handleNextTrack);
          tooglePlayPause(true);
        }
      };
    }
  }, [actualTrack]);

  useEffect(() => {
    const audio = audioRef.current;
    if (
      actualTrack?.track?.id &&
      audio.src !== DirectusAudio(actualTrack.track.id)
    ) {
      audio.src = DirectusAudio(actualTrack.track.id); // Set the new track URL only if it changes

      if (play) {
        audio.play();
      }
    }
  }, [actualTrack, play]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleLoadedMetadata = () => setTotalTime(audio.duration);
    const handleEnded = () => {
      // Stop the playback when the track ends
      setCurrentTime(totalTime); // Set currentTime to the end to reflect the end position
      tooglePlayPause(false); // Pause the playback
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("ended", handleEnded);
    };
  }, [totalTime, play, pause]);

  useEffect(() => {
    const audio = audioRef.current;
    if (pause) {
      audio.pause(); // Pause the audio without resetting the currentTime
    } else if (play && !pause) {
      audio.play(); // Play the audio from the current position
    }
  }, [pause, play]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.src) {
      if (!audio.paused) {
        audio.pause();
        tooglePlayPause(false);
      } else {
        audio.play();
        tooglePlayPause(true);
      }
    }
  };

  // function to change audio volume
  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleTimeChange = (event, newValue) => {
    setCurrentTime(newValue);
    audioRef.current.currentTime = newValue;
  };

  // function to change audio time
  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds === Infinity) {
      return "0:00";
    }
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  // function to close mediaPlayer and erase cookies
  const closeMediaPlayer = () => {
    tooglePlayPause(false);
    setTimeout(() => {
      toggleMediaPlayerModal(false);
    }, 300);
  };

  const handleOpenArtistProfile = () => {
    history.push(
      `/user/${
        actualTrack?.track?.artist.username ?? actualTrack?.track?.artist.id
      }`
    );
  };

  return (
    <div className={`${styles.media_container} ${styles.show}`}>
      <div className={styles.media_container__song_info}>
        <CloseIcon onClick={() => closeMediaPlayer()} />
        <img src={DirectusImage(actualTrack?.track?.artwork)} alt="Song" />
        <div>
          <h4>
            {truncate(
              actualTrack?.track?.title ?? actualTrack?.track?.title,
              20,
              "..."
            )}
          </h4>

          <p onClick={() => handleOpenArtistProfile()}>
            {truncate(
              actualTrack?.track?.artist?.username ??
                actualTrack?.track?.artist?.username,
              20,
              "..."
            )}
          </p>
        </div>
      </div>
      <div className={styles.media_container__controls}>
        <div className={styles.media_container__controls_control_buttons}>
          <IconButton
            onClick={() => {
              handlePrevTrack();
            }}
            className={styles.icon_button}
          >
            <SkipPrevious className={styles.icon} />
          </IconButton>
          <IconButton
            onClick={handlePlayPause}
            className={styles.play_pause_button}
          >
            {play ? (
              <Pause className={styles.icon} />
            ) : (
              <PlayCircleFilledWhite className={styles.icon} fontSize="large" />
            )}
          </IconButton>
          <IconButton
            onClick={() => {
              handleNextTrack();
            }}
            className={styles.icon_button}
          >
            <SkipNext className={styles.icon} />
          </IconButton>
        </div>
        <div className={styles.time_info}>
          <span className={styles.current_time}>{formatTime(currentTime)}</span>
          <TimeSlider
            value={currentTime}
            min={0}
            max={totalTime}
            onChange={handleTimeChange}
            aria-labelledby="time-slider"
            className={styles.time_slider}
          />
          <span className={styles.total_time}>{formatTime(totalTime)}</span>
        </div>
      </div>
      <div className={styles.volume_control}>
        <VolumeUp className={styles.icon} />
        <GraySlider
          value={volume}
          onChange={handleVolumeChange}
          aria-labelledby="volume-slider"
          className={styles.volume_slider}
        />
      </div>
    </div>
  );
}
