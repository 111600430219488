import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useHook } from "app/hooks/common";
import { getParam } from "app/core/common";
import MusicTable from "./musicTable";
import { useLocation } from "react-router-dom";
import MusicList from "./musicList";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import axios from "axios";
import Loader from "app/components/Loader";
import { useHistory } from "react-router-dom";
import AlbumView from "./albumView";

interface Track {
  track: {
    id: string;
    title: string;
    artist: {
      username: string;
    };
    artwork: string;
    duration: number;
  };
}

interface Album {
  id: string;
  name: string;
  tracks: number;
  artist: string;
}

// Define the type for the index
type Index = number;

export default function MusicLibrary() {
  const { cookie } = useHook();
  const [loading, setLoading] = useState<boolean>(false);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tracks, setTracks] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [albumId, setAlbumId] = useState(getParam("album"));
  const [albumView, setAlbumView] = useState(true);
  const [trackView, setTrackView] = useState(false);
  const history = useHistory();
  const location = useLocation();

  // Listen for query parameter changes and toggle views accordingly
  useEffect(() => {
    const param = getParam("album");

    if (param) {
      setAlbumId(param);
      setAlbumView(false); // Switch to track view when album param is present
    } else {
      setAlbumView(true); // Switch back to album view when album param is removed
    }
  }, [location]);

  const mockAlbums = [
    {
      id: "1",
      name: "Summer Vibes",
      tracks: 12,
      artist: "DJ Cool",
    },
    {
      id: "2",
      name: "Chill Beats",
      tracks: 8,
      artist: "Lo-Fi Producer",
    },
  ];

  const mockTracks = [
    {
      track: {
        id: "101",
        title: "Sunset Boulevard",
        artist: {
          username: "DJ Cool",
        },
        artwork: "https://example.com/artwork/sunset-boulevard.jpg",
        duration: 215, // in seconds
      },
    },
    {
      track: {
        id: "102",
        title: "Chill Morning",
        artist: {
          username: "Lo-Fi Producer",
        },
        artwork: "https://example.com/artwork/chill-morning.jpg",
        duration: 198, // in seconds
      },
    },
    {
      track: {
        id: "103",
        title: "Beach Party",
        artist: {
          username: "DJ Cool",
        },
        artwork: "https://example.com/artwork/beach-party.jpg",
        duration: 180, // in seconds
      },
    },
    {
      track: {
        id: "104",
        title: "Evening Breeze",
        artist: {
          username: "Lo-Fi Producer",
        },
        artwork: "https://example.com/artwork/evening-breeze.jpg",
        duration: 230, // in seconds
      },
    },
  ];

  const {
    toggleMediaPlayerModal,
    tooglePlayPause,
    handleTrack,
    showPlayerModal,
    actualTrack,
    play,
    handleTracks,
  } = useMediaPlayer();

  // Fetch albums on component mount
  useEffect(() => {
    const fetchAlbums = async () => {
      setLoading(true);
      try {
        // const response = await axios.get(
        //   `${process.env.REACT_APP_MIDDLEWARE}music/albums`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       user_cookie: cookie,
        //     },
        //   }
        // );

        // if (response?.data) {
        //   setAlbums(response.data);
        // }
        setAlbums(mockAlbums);
      } catch (error) {
        console.error("Error fetching albums:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAlbums();
  }, [cookie]);

  // Fetch songs of selected album
  useEffect(() => {
    if (albumId) {
      const fetchTracks = async () => {
        setLoading(true);
        try {
          // const response = await axios.get(
          //   `${process.env.REACT_APP_MIDDLEWARE}music/albums/${albumId}/tracks`,
          //   {
          //     headers: {
          //       "Content-Type": "application/json",
          //       user_cookie: cookie,
          //     },
          //   }
          // );
          // if (response?.data) {
          //   setTracks(response.data);
          // }
        } catch (error) {
          console.error("Error fetching tracks for album:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchTracks();
    }
  }, [albumId, cookie]);

  useEffect(() => {
    const fetchMusic = async () => {
      setLoading((prev) => !prev);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}music/library`,
          {
            headers: {
              "Content-Type": "application/json",
              user_cookie: cookie,
            },
          }
        );

        if (response) {
          setTracks(response.data);
        }
        setLoading((prev) => !prev);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMusic();
  }, [cookie]);

  const handlePlayPause = (track: Track, index: Index) => {
    if (
      actualTrack?.track?.id === track?.track?.id &&
      showPlayerModal &&
      play
    ) {
      tooglePlayPause(false); // Pause the current song
      setPlayingIndex(null);
    } else {
      if (tracks?.length > 1) {
        handleTracks(tracks, index); // Pass the tracks array and the current index
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      } else {
        handleTrack(track); // Set the current track
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAlbumSelect = (album: Album) => {
    history.push({
      pathname: "/music-library",
      search: `?album=${album.id}`, // Set the album as a query parameter
    });
  };

  if (albumView) {
    return (
      <AlbumView
        albums={albums}
        handleAlbumSelect={handleAlbumSelect}
        loading={loading}
      />
    );
  }

  return (
    <div className={styles.music_library_container}>
      <div className={styles.music_library_container__header}>
        <div className={styles.music_library_container__header_infos}>
          <div className={styles.music_library_container__header_infos_details}>
            <div
              className={
                styles.music_library_container__header_infos_details_text
              }
            >
              <h1>Music Library</h1>
              <p>{tracks?.length} Tracks</p>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(23, 25, 27, 0.48)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : windowWidth > 767 ? (
        <div className={styles.musicTableContainer}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Album</th>
                <th>Track</th>
                <th>Artist</th>
                <th>Duration</th>
                <th style={{ color: "transparent" }}>Playing</th>
              </tr>
            </thead>
            <tbody>
              {tracks?.map((track, index) => (
                <MusicTable
                  key={index}
                  track={track}
                  index={index}
                  isPlaying={playingIndex === index}
                  handlePlayPause={() => handlePlayPause(track, index)}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <hr />
          {tracks?.map((track, index) => (
            <MusicList
              key={index}
              track={track}
              handlePlaySong={() => handlePlayPause(track, index)}
            />
          ))}
        </>
      )}
    </div>
  );
}
