import Loader from "app/components/Loader";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
export default function AlbumView({
  albums,
  loading,
  handleAlbumSelect,
}: {
  albums?: any;
  loading?: any;
  handleAlbumSelect?: any;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.music_library_container}>
      <div className={styles.music_library_container__header}>
        <div className={styles.music_library_container__header_infos}>
          <div className={styles.music_library_container__header_infos_details}>
            <div
              className={
                styles.music_library_container__header_infos_details_text
              }
            >
              <h1>Music Library</h1>
              <p>
                {albums?.length <= 1
                  ? albums?.length + " Album"
                  : albums?.length + " Albums"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(23, 25, 27, 0.48)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : windowWidth > 767 ? (
        <div className={styles.musicTableContainer}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Album</th>
                <th>Name</th>
                <th>Artist</th>
                <th>Tracks</th>
                <th style={{ color: "transparent" }}>Playing</th>
              </tr>
            </thead>
            <tbody>
              {albums?.map((album, index) => (
                <tr key={index} onClick={() => handleAlbumSelect(album)}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={DirectusImage(album?.track?.artwork)}
                      alt=""
                      className={styles.tableImage}
                    />
                  </td>
                  <td>{album?.name}</td>
                  <td>{album?.artist}</td>
                  <td>{album?.tracks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <hr />
          {albums?.map(
            (album, index) => (
              console.log(album),
              (
                <div className={styles.music_list}>
                  <div className={styles.music_list__item}>
                    <img src={DirectusImage(album?.track?.artwork)} alt="" />
                    <div className={styles.music_list__info}>
                      <h4>{truncate(album?.name ?? album?.name, 30, "...")}</h4>
                      <p>
                        {truncate(album?.artist ?? album?.artist, 30, "...")}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )
          )}
        </>
      )}
    </div>
  );
}
